import * as React from 'react'
import HomeSvg from '../assets/svg/home.svg'
import MailSvg from '../assets/svg/mail.svg'
import PortfolioSvg from '../assets/svg/portfolio.svg'
import TechnologiesSvg from '../assets/svg/technologies.svg'
import UserSvg from '../assets/svg/user.svg'

import '../styles/components/AnchorFixedContainer.scss'

const sections = {
  WELCOME_CONTAINER: {
    id: 'welcome-container',
    tooltipText: 'Welcome Container',
    icon: <HomeSvg className='icon'/>
  },
  ABOUT_ME: {
    id: 'about-me',
    tooltipText: 'About Me',
    icon: <MailSvg className='icon'/>
  },
  WHERE_WORKED: {
    id: 'where-worked',
    tooltipText: "Where I've worked",
    icon: <PortfolioSvg className='icon'/>
  },
  TECHNOLOGIES: {
    id: 'technologies',
    tooltipText: 'Technologies',
    icon: <TechnologiesSvg className='icon'/>
  },
  SAY_HI: {
    id: 'say-hi',
    tooltipText: 'Say hi!',
    icon: <UserSvg className='icon'/>
  }
}

function AnchorFixedContainer() {
  const smoothScrollToSection = (event) => {
    event.preventDefault()
    event.stopPropagation()
  
    const elementId = event.currentTarget.getAttribute('href');
    document.querySelector(elementId).scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }

  return (
    <div id='anchor-fixed-container'>
    {
      Object.values(sections).map(section => 
        <a key={section.id} onClick={smoothScrollToSection} href={`#${section.id}`} className='anchor'>
          {section.icon}
          <div className='tooltip-text'>{section.tooltipText}</div>
        </a>
      )
    }
  </div>
  )
}

export default AnchorFixedContainer;