import * as React from 'react'

import '../../styles/sections/Technologies.scss'

const technologies = [
  'Vue js','HTML','JavaScript','(S)CSS','Java','Ruby','NodeJs','ReactJs','GraphQl','Ruby on Rails'
]

const items = [
  <img src="path-to-img" />,
  <img src="path-to-img" />,
  <img src="path-to-img" />,
];

function Technologies() {
  return (
    <section className='technologies-container section container' id='technologies'>
    <div className='title-container'>
      <h2 className='title'><span className='number'>3. </span>Technologies</h2>
      <p className='description'>Here are a few technologies that I've worked</p>
    </div>
    <div className='technologies-list'>
      {
        technologies.map((tech) => {
          return (<div key={tech} className='technologies-item'>{tech}</div>)
        })
      }
    </div>
  </section>
  )
}

export default Technologies;