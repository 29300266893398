import * as React from 'react'

import '../styles/components/TerminalHeader.scss'

function TerminalHeader() {
  return (
    <div className='header'>
    <div className='header-email'>joao.dacol.soares@gmail.com</div>
    <div className='terminal-buttons'>
      <div className='minimize'></div>
      <div className='maximize'></div>
      <div className='close'></div>
    </div>
  </div>
  )
}

export default TerminalHeader;