import * as React from 'react'
import profileImage from '../../assets/profile-photo.png'

import '../../styles/sections/AboutMe.scss'

function AboutMe() {
  return (
    <section className='about-me-container section container' id='about-me'>
      <div className='title-container'>
        <h2 className='title' ><span className='number'>1. </span>About me</h2>
        <p className='description'>I'm a Developer that loves the whole developing process.</p>
        <p className='description'>
          I consider the fact that I am a fast learner and self-taught to be my biggest
          advantage in the professional growth path. I'm very passionate about new technologies
          and frameworks, and I love to be in a place that I can learn, grow, work as a team,
          improve quality and innovate.
        </p>
      </div>
      <div className='profile-image-container'>
        <div className='profile-image'>
          <img src={profileImage} />
        </div>
      </div>
    </section>
  )
}

export default AboutMe;