import * as React from 'react'
import '../styles/index.scss'
import '../styles/terminal.scss'
import '../styles/utilities/transitions.scss'
import 'fontsource-ubuntu'
import 'fontsource-raleway'

import { useState } from 'react';

import AboutMeSection from '../components/sections/AboutMe'
import TechnologiesSection from '../components/sections/Technologies'
import WhereWorkedSection from '../components/sections/WhereWorked'
import WelcomeSection from '../components/sections/Welcome'
import SayHiSection from '../components/sections/SayHi'

import AnchorFixedContainer from '../components/AnchorFixedContainer'
import SocialMediasFixedContainer from '../components/SocialMediasFixedContainer'

class Animator {
  animate(time, elementId) {
    return new Promise(function(resolve, reject) {
      document.getElementById(elementId).classList.add('animate');
      setTimeout(resolve, time);
    })
  }
}

const IndexPage = () => {
  React.useEffect(() => {
    startAnimation()
    return
  }, []);

  const [animationHasFinished, setAnimationHasFinished] = useState(false);

  const startAnimation = async () => {
    const animator = new Animator();
    await animator.animate(500,'main-component')
    await animator.animate(1000,'first-step-container')
    await animator.animate(2500,'script-name')
    await animator.animate(1000,'welcome-title')
    await animator.animate(800,'say-hi-button')
    await animator.animate(500,'anchor-fixed-container')
    await animator.animate(1000,'brand-fixed-container')
    setAnimationHasFinished(true)
  }

  const PAGE_TITLE = "João Soares"

  return (
    <main id='main-component'>
      <title>{PAGE_TITLE}</title>
      <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"></meta>
      <div className='terminal-container'>  
        <AnchorFixedContainer/>
        <SocialMediasFixedContainer/>

        <WelcomeSection/>
        {
          animationHasFinished ? 
            (
              <div>
                <AboutMeSection/>
                <WhereWorkedSection/>
                <TechnologiesSection/>
                <SayHiSection/>
              </div>
            ) : ''
        }
      </div>
    </main>
  )
}

export default IndexPage
