import * as React from 'react'
import LinkedinSvg from '../assets/svg/linkedin.svg'
import GithubSvg from '../assets/svg/github.svg'

import '../styles/components/SocialMediaFixedContainer.scss'

const socialMedias = {
  LINKEDIN: {
    icon: <LinkedinSvg/>,
    link: 'https://www.linkedin.com/in/jo%C3%A3odacolsoares/'
  },
  GITHUB: {
    icon: <GithubSvg/>,
    link: 'https://github.com/joaodacolsoares'
  }
}

function BrandFixedContainer() {
  return (
    <div id='brand-fixed-container'>
    {
      Object.values(socialMedias).map(socialMedia => 
        <a key={socialMedia.link} href={`${socialMedia.link}`} className='icon' target='_blank'>
          {socialMedia.icon}
        </a>
      )
    }
    <div className='line'></div>
  </div>
  )
}

export default BrandFixedContainer;