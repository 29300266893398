import * as React from 'react'

import '../../styles/sections/Welcome.scss'
import SayHiButton from '../SayHiButton'
import TerminalHeader from '../TerminalHeader'


function MyComponent() {
  return (
    <section id='first-step-container'>
    <TerminalHeader/>
    <div className='welcome-container container' id='welcome-container'>
      <div className='terminal-line-container'>
        <span className='right-arrow'>➜</span>
        <span className='terminal-start'>~</span>
        <div id='script-name'>./introduction.sh <span className="cursor">&nbsp;</span></div>
      </div>
      
      
      <h1 id='welcome-title'>
        Hello, {'{visitor}'} <br /> I am <span className='highlight-title'>João</span>
      </h1>


      <SayHiButton id='say-hi-button' classname=''/>
    </div>
  </section>
  )
}

export default MyComponent;