import * as React from 'react'
import SayHiButton from '../SayHiButton'

import '../../styles/sections/SayHi.scss'

function SayHi() {
  return (
    <section className='say-hi-container section container' id='say-hi'>
    <div className='title-container'>
      <h2 className='title'><span className='number'>4. </span>Say hi!</h2>
      <p className='description'>
        If you want to reach me, just click in the button, my inbox is always open. Whether you have a question or just want to say hi, I'll try my best to get back to you!
      </p>
    </div>
    <SayHiButton classname='show' />
  </section>
  )
}

export default SayHi;