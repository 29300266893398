import * as React from 'react'

import '../styles/components/SayHiButton.scss'

function sayHiButton({ classname, id }) {
  return (
    <a id={id} href='mailto:joao.dacol.soares@gmail.com' className={`say-hi-button ${classname}`}>Say hi!</a>
  )
}

export default sayHiButton;