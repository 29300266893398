

import * as React from 'react'

import { useState } from 'react';
import { CSSTransition, SwitchTransition } from "react-transition-group";

import '../../styles/sections/WhereWorked.scss'


const jobs = [
  {
    companyName: 'SAP',
    positions: [
      {
        title: 'Developer',
        startDate: "August 2020",
        endDate: "At this Moment",
        description: "As a Developer in SAP NA HCM Team, I had the opportunity to work in connections with many different people and cultures, mainly working to solve customer issues and developing new products in a microservice architecture. Technically, I've worked with SAPUi5, Java with Spring, and SAP Cloud Platform.",
        inFocus: true
      },
      {
        title: 'Developer Intern',
        startDate: "August 2019",
        endDate: "July 2020",
        description: "As a Developer Intern in SAP IBSO (Innovative Business Solutions Brazil) Team, I had the opportunity to work on many different projects, developing custom applications for specific customer needs from design to the delivery phase. Also, I've worked in an agile team with Scrum methodology and had the opportunity to learn the diversity and feedback culture of the company. Technically, I've worked with SAPUi5, Java with Spring, ReactJS, React Native, working with third-party integrations, and a little bit of Swift."
      }
    ]
  },
  {
    companyName: 'Wedy',
    positions: [
      {
        title: 'Software Engineer',
        startDate: "January 2019",
        endDate: "July de 2019",
        description: "As a Software Engineer in Wedy, I had the opportunity to develop and bring insights to the company always focusing on ways to grow up the revenue and bringing the best experience to the user alongside the design team. Also, I had the opportunity to lead and participate in the hiring process of new members. Technically, I've worked with Vuejs, Cordova, Ruby on Rails, GraphQL APIs, HexoJS, working with third-party integrations and doing DevOps on Amazon AWS."
      },
      {
        title: 'Software Engineer Intern',
        startDate: "September 2018",
        endDate: "December 2018",
        description: "As a Software Engineer Intern in Wedy, I had the opportunity to develop and improve Wedy’s projects implementing a scalable visual identity lined up with the design team. Technically, I've worked with Vuejs, Ruby on Rails, and building GraphQL APIs."
      }
    ]
  },
  {
    companyName: 'GVDASA',
    positions: [
      {
        title: 'Web Developer Intern',
        startDate: "May 2018",
        endDate: "August 2018",
        description: "It was my first job experience. I had the opportunity to develop applications working in an agile team with the Scrum methodology. Technically, I've worked with web development using Angular and Cordova to develop hybrid apps."
      }
    ]
  }
]

function WhereWorked() {
  const [selectedJob, setSelectedJob] = useState({
    companyName: jobs[0].companyName,
    position: jobs[0].positions[0]
  });

  const onChangeSelectedJob = (position, companyName) => {
    position.inFocus = true;
    setSelectedJob(selectedJob.position.inFocus = false)    
    setSelectedJob({ companyName, position })
  }

  const jobsList = jobs.map((job) => {
    return (
      <li className='job-item' key={job.companyName}>
        <div className='company-name'>{job.companyName}</div>
        <ol className='position-list'>
          {
            job.positions.map(position =>
              <li key={position.title} className={position.inFocus ? 'focused' : ''}
                onClick={() => onChangeSelectedJob(position, job.companyName)}>
                {position.title}
              </li>)
          }
        </ol>
      </li>
    )
  })

  // const mobileJobsList = jobs.map(({ positions, companyName }) => {
  //   return positions.map(position =>
  //     <div 
  //       key={position.title} 
  //       style={ { width: 'max-content', border: '1px #fff solid', padding: '1rem', marginRight: '1rem' } }
  //       className={`job-item ${position.inFocus ? 'focused' : ''}`}
  //       onClick={() => onChangeSelectedJob(position, companyName)}>
  //       {position.title} @ {companyName}
  //     </div>
  //   )  
  // }).flat();

  return (
    <section className='where-worked-container section container' id='where-worked'>
      <div className='title-container'>
        <h2 className='title'><span className='number'>2. </span>Where I’ve worked</h2>
      </div>
      <div className='jobs-container'>
        <ol className='jobs-list'>
          {
            jobsList
          }

          {/* {
            mobileJobsList
          } */}
        </ol>
        
        <SwitchTransition>
          <CSSTransition
            key={selectedJob.position.title}
            timeout={100}
            classNames="fade"
          >
            <div className='job-description-container'>
              <h3 className='job-title'>{selectedJob.position.title} <span>@ {selectedJob.companyName}</span></h3>
              <div className='job-time-period'>{selectedJob.position.startDate} - {selectedJob.position.endDate}</div>
              <p className='job-description'>{selectedJob.position.description}</p>
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>

    </section>
  )
}

export default WhereWorked;